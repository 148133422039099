









import Vue from 'vue'
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons'
import { IJobSearchFilters, IMoney } from '@/types'
import { BaseFieldMoney } from '@/components'
import debounce from 'lodash/debounce'
import { DEBOUNCE_TIME_MS } from '@/enums'

export default Vue.extend({
    name: 'FieldSalaryExpected',

    components: { BaseFieldMoney },

    props: {
        filters: {
            type: Object as () => IJobSearchFilters,
            required: true,
        },
    },

    data: () => ({ faHandHoldingUsd }),

    computed: {
        // 0 value for filter is null, but internally only number is valid
        salary: {
            get(): IMoney {
                return { value: this.filters.salary ?? 0, currency: 'PLN' }
            },
            set({ value }: IMoney) {
                const salary = value || null

                this.updateSalaryDebounced(salary)
            },
        },
    },

    methods: {
        updateSalaryDebounced: debounce(function (this: any, salary: number | null) {
            this.$emit('update:filters', { ...this.filters, salary })
        }, DEBOUNCE_TIME_MS),
    },
})
