







import Vue from 'vue'
import { ICity } from '@/types'

export default Vue.extend({
    name: 'Suggestion',

    props: {
        city: {
            type: Object as () => ICity,
            required: true,
        },
    },
})
