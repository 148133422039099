












import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { MUTATION_JOB_SEARCH, STORE_MODULE } from '@/enums'
import { IJobSearchFilters, IStateJobSearch } from '@/types'
import {
    FilterContainer,
    FieldSearchText,
    FieldSalaryExpected,
    FieldCity,
    FieldCompany,
} from './components'

const { mapState, mapMutations } = createNamespacedHelpers(STORE_MODULE.JOB_SEARCH)

type IValueConverters = {
    [K in keyof IJobSearchFilters]: (arg: string) => IJobSearchFilters[K]
}

const valueConverters: IValueConverters = {
    searchText: (value) => value,
    cityId: (id) => (id ? parseInt(id) : null),
    companyId: (id) => (id ? parseInt(id) : null),
    salary: (value) => (value ? parseInt(value) : null),
}

export default Vue.extend({
    name: 'Filters',

    components: {
        FilterContainer,
        FieldSearchText,
        FieldSalaryExpected,
        FieldCity,
        FieldCompany,
    },

    computed: {
        ...(mapState(['filters']) as MapStateToComputed<IStateJobSearch>),

        filtersInternal: {
            get(): IJobSearchFilters | undefined {
                return this.filters
            },
            set(filters: IJobSearchFilters) {
                this.setQueryFromFilters(filters)
                this.setFilters(filters)
            },
        },
    },

    created() {
        if (this.filters) {
            this.setQueryFromFilters(this.filters)
        } else {
            this.filtersInternal = {
                ...this.getDefaultFilters(),
                ...this.getFiltersFromQuery(),
            }
        }
    },

    methods: {
        ...mapMutations({ setFilters: MUTATION_JOB_SEARCH.SET_FILTERS }),

        getDefaultFilters(): IJobSearchFilters {
            return {
                searchText: '',
                cityId: null,
                companyId: null,
                salary: null,
            }
        },

        getFiltersFromQuery(): Partial<IJobSearchFilters> {
            return Object.entries(valueConverters).reduce(
                (result, [propName, propConverter]) => {
                    const propValue = this.$route.query[propName] as string

                    if (propValue) result[propName] = propConverter(propValue)

                    return result
                },
                {} as Record<string, IJobSearchFilters[keyof IJobSearchFilters]>
            )
        },

        async setQueryFromFilters(filters: IJobSearchFilters) {
            const query = Object.entries(filters).reduce((result, [propName, value]) => {
                if (value || value === 0) result[propName] = value.toString()

                return result
            }, {} as Record<string, string>)

            try {
                await this.$router.replace({ query })
            } catch (error) {}
        },
    },
})
