










import Vue from 'vue'
import { IJob } from '@/types'
import { BaseJobListItem } from '@/components'
import { FetchMoreDetector } from './components'

export default Vue.extend({
    name: 'JobList',

    components: { BaseJobListItem, FetchMoreDetector },

    props: {
        jobs: {
            type: Array as () => IJob[],
            required: true,
        },
    },
})
