









import Vue from 'vue'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { BaseFieldText } from '@/components'
import { IJobSearchFilters } from '@/types'
import { DEBOUNCE_TIME_MS } from '@/enums'
import debounce from 'lodash/debounce'

export default Vue.extend({
    name: 'FieldSearchText',

    components: { BaseFieldText },

    props: {
        filters: {
            type: Object as () => IJobSearchFilters,
            required: true,
        },
    },

    data: () => ({ faSearch }),

    computed: {
        searchText: {
            get(): string {
                return this.filters.searchText
            },
            set(searchText: string) {
                this.updateSearchTextDebounced(searchText)
            },
        },
    },

    methods: {
        updateSearchTextDebounced: debounce(function (this: any, searchText: string) {
            this.$emit('update:filters', { ...this.filters, searchText })
        }, DEBOUNCE_TIME_MS),
    },
})
