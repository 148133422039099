





















import Vue from 'vue'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { BaseFieldTypeahead } from '@/components'
import { Suggestion } from './components'
import { DEBOUNCE_TIME_MS } from '@/enums'
import { ICity, IJobSearchFilters } from '@/types'
import { REPOSITORY_JOBS } from '@/repositiories'
import debounce from 'lodash/debounce'

export default Vue.extend({
    name: 'FieldCity',

    components: { BaseFieldTypeahead, Suggestion },

    props: {
        filters: {
            type: Object as () => IJobSearchFilters,
            required: true,
        },
    },

    computed: {
        cityId: {
            get(): number | null {
                return this.filters.cityId
            },
            set(cityId: number | null) {
                this.$emit('update:filters', { ...this.filters, cityId })
            },
        },
    },

    data: () => ({
        searchText: '',
        cities: [] as ICity[],
        isLoadingCities: false,
        faMapMarkerAlt,
    }),

    async created() {
        if (!this.cityId) return

        try {
            const city = await REPOSITORY_JOBS.fetchJobCity(this.cityId)

            this.searchText = city.name
            this.cities = [city]
        } catch (error) {
            this.cityId = null
        }
    },

    methods: {
        serializer(city: ICity) {
            return city.name
        },

        clearCityId() {
            this.cityId = null
        },

        fetchCitiesDebounced: debounce(function (this: any, searchText: string) {
            this.fetchCities(searchText)
        }, DEBOUNCE_TIME_MS),

        async fetchCities(searchText: string) {
            this.isLoadingCities = true

            try {
                const { items } = await REPOSITORY_JOBS.fetchJobCities({ searchText })

                this.cities = items
            } catch (error) {
                alert(error.data.message)
            }

            this.isLoadingCities = false
        },

        setCity(city: ICity) {
            this.searchText = city.name
            this.cityId = city.id
        },
    },
})
