








import Vue from 'vue'
import { BaseSpinner, BaseIntersectionDetector } from '@/components'
import { createNamespacedHelpers } from 'vuex'
import { ACTION_JOB_SEARCH, STORE_MODULE } from '@/enums'
import { IStateJobSearch } from '@/types'

const { mapState, mapActions } = createNamespacedHelpers(STORE_MODULE.JOB_SEARCH)

export default Vue.extend({
    name: 'FetchMoreDetector',

    components: { BaseSpinner, BaseIntersectionDetector },

    data: () => ({
        isFetching: false,
    }),

    computed: {
        ...(mapState(['jobs', 'hasFetchedAllJobs']) as MapStateToComputed<IStateJobSearch>),

        canFetchMore(): boolean {
            return !this.isFetching && !this.hasFetchedAllJobs && !!this.jobs?.length
        },
    },

    methods: {
        ...mapActions({ fetchAndAddMore: ACTION_JOB_SEARCH.FETCH_AND_ADD_MORE_JOBS }),

        async fetchMore(): Promise<void> {
            this.isFetching = true

            await this.fetchAndAddMore()

            this.isFetching = false
        },
    },
})
