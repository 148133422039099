
















import Vue from 'vue'
import { BaseFieldContainer } from '@/components'
import { BtnToggle } from './components'
import { createNamespacedHelpers } from 'vuex'
import { STORE_MODULE } from '@/enums'
import { IStateJobSearch } from '@/types'

const { mapState } = createNamespacedHelpers(STORE_MODULE.JOB_SEARCH)

export default Vue.extend({
    name: 'FilterContainer',

    components: { BaseFieldContainer, BtnToggle },

    computed: {
        ...(mapState(['areFiltersExpanded']) as MapStateToComputed<IStateJobSearch>),
    },
})
