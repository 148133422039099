













import Vue from 'vue'
import { faChevronDown, faChevronUp, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { BaseBtn } from '@/components'
import { createNamespacedHelpers } from 'vuex'
import { MUTATION_JOB_SEARCH, STORE_MODULE } from '@/enums'
import { IStateJobSearch } from '@/types'

const { mapState, mapMutations } = createNamespacedHelpers(STORE_MODULE.JOB_SEARCH)

export default Vue.extend({
    name: 'BtnToggle',

    components: { BaseBtn },

    computed: {
        ...(mapState(['filters', 'areFiltersExpanded']) as MapStateToComputed<IStateJobSearch>),

        icon(): IconDefinition {
            return this.areFiltersExpanded ? faChevronUp : faChevronDown
        },

        label(): string {
            return this.areFiltersExpanded ? 'Less' : 'More'
        },

        activeExpandableFilters(): number {
            const { searchText, ...filters } = this.filters!

            return Object.values(filters).filter((filter) => {
                return filter != null || !!filter?.length
            }).length
        },
    },

    methods: {
        ...mapMutations({
            setAreFiltersExpanded: MUTATION_JOB_SEARCH.SET_ARE_FILTERS_EXPANDED,
        }),

        toggleFilters() {
            this.setAreFiltersExpanded(!this.areFiltersExpanded)
        },
    },
})
