





















import Vue from 'vue'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import { BaseFieldTypeahead } from '@/components'
import { DEBOUNCE_TIME_MS } from '@/enums'
import { ICompany, IJobSearchFilters } from '@/types'
import { REPOSITORY_JOBS } from '@/repositiories'
import debounce from 'lodash/debounce'

export default Vue.extend({
    name: 'FieldCompany',

    components: { BaseFieldTypeahead },

    props: {
        filters: {
            type: Object as () => IJobSearchFilters,
            required: true,
        },
    },

    computed: {
        companyId: {
            get(): number | null {
                return this.filters.companyId
            },
            set(companyId: number | null) {
                this.$emit('update:filters', { ...this.filters, companyId })
            },
        },
    },

    data: () => ({
        searchText: '',
        companies: [] as ICompany[],
        isLoadingCompanies: false,
        faBuilding,
    }),

    async created() {
        if (!this.companyId) return

        try {
            const company = await REPOSITORY_JOBS.fetchJobCompany(this.companyId)

            this.searchText = company.name
            this.companies = [company]
        } catch (error) {
            this.companyId = null
        }
    },

    methods: {
        serializer(company: ICompany) {
            return company.name
        },

        clearCompanyId() {
            this.companyId = null
        },

        fetchCompaniesDebounced: debounce(function (this: any, searchText: string) {
            this.fetchCompanies(searchText)
        }, DEBOUNCE_TIME_MS),

        async fetchCompanies(searchText: string) {
            this.isLoadingCompanies = true

            try {
                const { items } = await REPOSITORY_JOBS.fetchJobCompanies({ searchText })

                this.companies = items
            } catch (error) {
                alert(error.data.message)
            }

            this.isLoadingCompanies = false
        },

        setCompany(company: ICompany) {
            this.searchText = company.name
            this.companyId = company.id
        },
    },
})
