













import Vue from 'vue'
import { BaseSection, BaseSpinner } from '@/components'
import { Filters, JobList } from './components'
import { createNamespacedHelpers } from 'vuex'
import { ACTION_JOB_SEARCH, STORE_MODULE } from '@/enums'
import { IJobSearchFilters, IStateJobSearch } from '@/types'

const { mapState, mapActions } = createNamespacedHelpers(STORE_MODULE.JOB_SEARCH)

export default Vue.extend({
    name: 'JobSearch',

    components: { BaseSection, BaseSpinner, Filters, JobList },

    computed: {
        ...(mapState(['filters', 'jobs']) as MapStateToComputed<IStateJobSearch>),
    },

    watch: {
        filters() {
            this.fetchAndSetJobs()
        },
    },

    async created() {
        const isBack = await this.$isRouterBack()

        if (this.filters && this.jobs && !isBack) this.fetchAndSetJobs()
    },

    methods: {
        ...mapActions({ fetchAndSetJobs: ACTION_JOB_SEARCH.FETCH_AND_SET_JOBS }),
    },
})
